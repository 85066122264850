/**
 *service
 */
import axios from 'axios'
/**
 * const
 */
import {
    BLOB_UPLOAD_FILE_URL,
    BLOB_GET_FILE_URL
} from "../constants/api.constant";
import {QueryString} from "../utils/Querystring";


export default class BlobService {
    static async getFile(params = {}) {
        return await axios.get(BLOB_GET_FILE_URL, {
            params,
            paramsSerializer: params => {
                return QueryString.stringify({
                    ...params,
                });
            },
        })
            .catch(e => Promise.reject(e))
    }

    static async uploadFile(url, data, params = {}) {
        return await axios.post(url, data, { params })
            .catch(e => Promise.reject(e))
    }

}
