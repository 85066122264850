/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Input} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
/**
 * enums
 */
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import FieldItem from "../../../../../Form/components/FieldItem";
import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactQuill from 'react-quill';
import {EditorState, convertToRaw, ContentState , convertFromRaw} from 'draft-js';
import 'react-quill/dist/quill.snow.css';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import {useTranslation} from "react-i18next";

export default function EditableMarkdownWidget({
    isFocused,
    widget,
    resultValue,
    onEdit,
    validationErrorMessage,
    field,
    onBlur,
    result
}) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [resultData, setResultData] = useState(result)
    const [openToolbar, setOpenToolbar] = useState(false)
    const {t } = useTranslation()
    const inputRef = useRef(null)


    useEffect(() => {
        if (resultValue) {
            const contentBlock = htmlToDraft(resultValue);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                setEditorState(EditorState.createWithContent(contentState));
            }
        }
    }, [resultValue]);

    const onBlurHandler = () => {
        if (onEdit) {
            onEdit(resultData)
        }

        if (onBlur) {
            onBlur(resultData)
        }
    }

    const onChangeHandler = (editorState) => {
        setEditorState(editorState);
        
        const value = draftToHtml(convertToRaw(editorState.getCurrentContent())) || null;

        const newMeasuresValue = field ? {
            ...resultData.measures,
            [field]: value,
        } : value

        setResultData({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
        onEdit({
            ...resultData,
            is_modified: true,
            measures: newMeasuresValue
        })
    }

    // const onContentStateChange = (editorState) => {
    //     console.log(draftToMarkdown(convertToRaw(editorState.getCurrentContent())));
    // };

    // useEffect(() => {
    //     setResultData(result)
    // }, [result])
    //
    // useEffect(() => {
    //     if(isFocused){
    //         inputRef.current.focus()
    //     }
    // }, [])

    return (
        <FieldItem label={widget.description || ""}  required={!widget.type.includes("null")} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
            <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                <div className={styles.wrapper__toolbarBtn} onClick={() => setOpenToolbar(!openToolbar)}>
                    {openToolbar ? (
                        <img src="/image/icon/toolbarDown.svg" alt=""/>
                    ) : (
                        <img src="/image/icon/toolbarUp.svg" alt=""/>
                    )}
                </div>
                <Editor
                    toolbarHidden={!openToolbar}
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                        fontSize: {
                            options: [8, 9, 10, 12, 14, 16, 18, 24, 30, 36],
                        },
                    }}
                    onEditorStateChange={onChangeHandler}
                />
            </div>
        </FieldItem>
    )
    

    // return (
    //     <FieldItem label={widget.description || ""} stylesWrapper={{marginBottom: 10}} error={validationErrorMessage}>
    //         <div className={styles.wrapper}>
    //             <ReactQuill theme="snow" defaultValue={resultValue || ""} onChange={onChangeHandler} />
    //         </div>
    //     </FieldItem>
    // )
}
