import MemberModel from "../member.model";
import ResultModel from "./result.model";
import {v4 as uuidv4} from "uuid";

export default class RecordModel {
    constructor(data = {}) {
        this.id = data.id || uuidv4().toUpperCase();
        this.created_at = data.created_at || new Date();
        this.relation = data.relation || null;
        this.label = data.label || null;
        this.tag = data.tag || null;
        this.new = data.new || false;
        this.member = data.member ? data.member.map(member => new MemberModel(member)) : [];
        this.result = data.result ? this.sortResult(data.result.map(result => new ResultModel({
            ...result,
            medCardMembers: data.medCardMembers,
            recordMembers: this.member,
            recordId: data.id
        }))) : [];
        this.allMembers = [
            ...(data.medCardMembers || []),
            ...this.member
        ]
    }

    sortResult(allResults) {
        function compareMembers(a, b) {
            const maxLength = Math.max(a.member?.length, b.member?.length);
            for (let i = 0; i < maxLength; i++) {
                const aOrdinal = (a.member[i] || {}).ordinal || Infinity; // Use Infinity for missing ordinals
                const bOrdinal = (b.member[i] || {}).ordinal || Infinity;

                if (aOrdinal < bOrdinal) {
                    return -1;
                } else if (aOrdinal > bOrdinal) {
                    return 1;
                }
                // If they are the same, continue to the next member
            }
            return 0; // If all ordinals are the same or both arrays are exhausted
        }

        return allResults.sort(compareMembers);
    }
}

const array = [
    {
        member: [
            {
                ordinal: 1
            },
            {
                ordinal: 1
            }
        ]
    },
    {
        member: [
            {
                ordinal: 2
            },
            {
                ordinal: 4
            }
        ]
    },
    {
        member: [
            {
                ordinal: 1
            },
            {
                ordinal: 2
            }
        ]
    },
    {
        member: [
            {
                ordinal: 1
            },
            {
                ordinal: 3
            }
        ]
    },
    {
        member: [
            {
                ordinal: 2
            },
            {
                ordinal: 3
            }
        ]
    },
    {
        member: [
            {
                ordinal: 2
            },
            {
                ordinal: 1
            }
        ]
    },
]
