/**
 * external libs
 */
import React, {useEffect, useRef, useState} from 'react'
import {Select} from "antd";
/**
 * styles
 */
import styles from './index.module.scss'
import ResultKeysEnum from "../../../../../../enums/api/ResultKeys";
import CatalogService from "../../../../../../services/catalog.service";
import IncarnationIdEnum from "../../../../../../enums/api/Incarnation";
import FieldItem from "../../../../../Form/components/FieldItem";
import generateIconUrl from "../../../../../../utils/generateIconUrl";
import GetIcon from "../../../../../GetIcon";
import {useTranslation} from "react-i18next";
import MembersIdEnum from "../../../../../../enums/api/MembersId";
import ActionsEnum from "../../../../../../enums/api/Actions";

export default function EditableLastMemberWidget({
                                                     lastMembers,
                                                     validateResultHandler,
                                                     validationErrorMessage,
                                                     onEdit,
                                                     widget,
                                                     changeMembersHandler,
                                                     catalogId,
                                                     isFocused,
                                                     folderId,
                                                     result
                                                 }) {
    const [resultData, setResultData] = useState(result)
    const [options, setOption] = useState(null)
    const [column, setColumn] = useState(null)
    const {t} = useTranslation()

    const onChangeHandler = (optionId) => {
        const newResultData = JSON.parse(JSON.stringify(result))
        newResultData.is_modified = true;

        if (!column.is_multiple) {
            const currentOption = options.find(option => option.id === optionId)

            newResultData.member[newResultData.member.length - 1].id = currentOption.id
            newResultData.member[newResultData.member.length - 1].name = currentOption.name

        }

        if (column.is_multiple) {
            newResultData.member = newResultData.member.filter((member) => {
                const currentOption = options.find(option => option.id === member.id)

                return !currentOption
            })

            const newMembers = optionId
                .map(id => {
                    const currentOption = options.find(option => option.id === id)
                    // const lastMember = newResultData.member[newResultData.member.length - 1];

                    if(!currentOption){
                        return null
                    }

                    return (
                        {
                            dimension: currentOption.dimension,
                            id: currentOption.id,
                            name: currentOption.name,
                        }
                    )
                })
                .filter(option => option)

            if(newMembers.length){
                newResultData.member = [
                    ...newResultData.member.filter(member => member.id !== MembersIdEnum.unassigned),
                    ...newMembers
                ]
            }

            if(!newMembers.length){
                newResultData.member = [
                    ...newResultData.member,
                    {
                        dimension: {
                            id: lastMembers[0].dimension.id
                        },
                        id: MembersIdEnum.unassigned
                    },
                    ...newMembers
                ]
            }

        }

        validateResultHandler(newResultData)
        onEdit(newResultData)
        changeMembersHandler(newResultData)
    }

    const getOptions = async (params = {}) => {
        const catalog = await CatalogService.content(params)
        const currentColumns = catalog.columns[0];

        const options = catalog.rows.reduce((options, contentItems) => {
            const findContentItem = contentItems.find(contentItem => contentItem.column === currentColumns.column)

            const findDuplicateOption = options.find(option => option.id === findContentItem.id)

            if (findDuplicateOption) {
                return options
            }

            return [...options, {...findContentItem, dimension: currentColumns.dimension}]
        }, [])

        lastMembers.forEach(member => {
            const findOption = options.find(({id}) => id === member.id);

            if(member.id === MembersIdEnum.unassigned && !widget.type.includes("null")){
                return;
            }

            if(!findOption){
                options.push(member)
            }
        })


        if(!options.find(({id}) => id === MembersIdEnum.unassigned) && widget.type.includes("null") && !currentColumns.is_multiple){
            options.push({
                dimension: {
                    id: lastMembers[0].dimension.id
                },
                id: MembersIdEnum.unassigned,
                name: t("Unassigned")
            })
        }
        setOption(options || [])
        setColumn(currentColumns || [])
    }

    useEffect(() => {
        const membersIdToContent = resultData.member
            .filter((_, index) => index < (resultData.member.length - lastMembers.length))
            .map(member => member.id)
            .join(',')

        const membersIdToContentFromRecord = resultData.recordMembers
            .map(member => member.id)
            .join(',')

        getOptions({
            incarnation_id: IncarnationIdEnum.id.result,
            action_id: ActionsEnum.edit,
            // folder_id: folderId,
            catalog_id: catalogId,
            global_members: `${membersIdToContent}`,
            local_members: membersIdToContentFromRecord,
            depth: 1
        })
    }, [])

    useEffect(() => {
        setResultData(result)
    }, [result])

    // useEffect(() => {
    //
    // }, [isFocused])

    const valueInOptions = options?.some(option => option.id === result.member[result.member.length - 1].id);
    const keyForMember = options && options.length && valueInOptions ? "id" : "name";

    if (!column) {
        return <div>Loading...</div>
    }


    if (!column.is_multiple) {
        let value = options  ? result.member[result.member.length - 1][keyForMember] : null;

        return (
            <FieldItem label={widget.description || ""} stylesWrapper={{marginBottom: 10}}
                       required={!widget.type.includes("null")}
                       error={validationErrorMessage}>
                <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                    <Select style={{width: "100%", minWidth: 130}}
                            className={styles.wrapper__select}
                            placeholder={t("Select data")}
                            value={value}
                            listHeight={1000}
                            autoFocus={isFocused}
                            defaultOpen={isFocused}
                            onChange={onChangeHandler}
                            loading={!options}>
                        {options && options.map(option => (
                            <Select.Option value={option.id}>
                                <div className={styles.wrapper__option}>
                                    {option.icon && (
                                        <div style={{width: 10, height: 10}}>
                                            <GetIcon backendUrl={generateIconUrl(option.icon, "icon")}/>
                                        </div>
                                    )}
                                    {option.name}
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </FieldItem>
        )
    }


    if (column.is_multiple) {
        return (
            <FieldItem label={widget.description || ""}
                       required={!widget.type.includes("null")}
                       stylesWrapper={{marginBottom: 10}}
                       error={validationErrorMessage}>
                <div className={styles.wrapper} style={{maxWidth: 500, width: 500}}>
                    <Select style={{width: "100%", minWidth: 130}}
                            className={styles.wrapper__select}
                            placeholder={t('Select data')}
                            autoFocus={isFocused}
                            defaultOpen={isFocused}
                            listHeight={1000}
                            mode={"multiple"}
                            value={options ? lastMembers.filter((member, index) => (member.id !== MembersIdEnum.unassigned)).map(member => {
                                const valueInOptions = options?.some(option => option.id === member.id);
                                const keyForMember = options && options.length && valueInOptions ? "id" : "name";

                                return member[keyForMember]

                            }) : null}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            onChange={onChangeHandler} loading={!options}>
                        {options && options.map(option => (
                            <Select.Option value={option.id} label={option.name}>
                                <div className={styles.wrapper__option}>
                                    {option.icon && (
                                        <div style={{width: 10, height: 10}}>
                                            <GetIcon backendUrl={generateIconUrl(option.icon, "icon")}/>
                                        </div>
                                    )}
                                    {option.name}
                                </div>
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </FieldItem>
        )
    }

    return null
}
