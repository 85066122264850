import {Validator} from 'jsonschema'
import {t} from "i18next";
import getWidget from "./getWidget";
import MembersIdEnum from "../enums/api/MembersId";


export class ValidateResult {
    static validResults(results){
        const isNotValid = results.some((result) => !ValidateResult.validResult(result));

        return !isNotValid
    }
    static validResultsTest(results){
        return results.map((result) => {
            const widget = getWidget(result);
            const allProperties = Object.keys(widget.result.schema.properties || {});

            if(allProperties.length){
                const validation = allProperties.map((property) => ValidateResult.validMeasures(result, widget, property))
                return validation
            }


            const validation = ValidateResult.validInstance(result, widget)
            return validation
        });
    }

    static validResult(result){
        const widget = getWidget(result);

        if(!widget){
            return true
        }

        const allProperties = Object.keys(widget.result.schema.properties || {});

        if(allProperties.length){

            const isNotValid = allProperties.some((property) => !ValidateResult.validMeasures(result, widget, property).valid)
            return !isNotValid
        }



        const isNotValid = !ValidateResult.validInstance(result, widget).valid
        return !isNotValid
    }

    static validInstance(result, widget){
        if(!result || !widget){
            return {
                valid: false,
                message: "You can provide result, widget"
            }
        }

        const widgetProperty = widget.result.schema;

        if(!widgetProperty){
            return {
                valid: false,
                message: "Can't find current member for this result"
            }
        }

        const validatorInstance = new Validator();
        const validationResult = validatorInstance.validate(result.measures, widget.result.schema)

        return {
            valid: validationResult.valid,
            error: validationResult,
            message: ValidateResult.getErrorMessage(validationResult.errors)
        }
    }

    static validMeasures(result, widget, field){
        if(!field){
            return ValidateResult.validInstance(result, widget)
        }

        if(!result || !widget || !field){
            return {
                valid: false,
                message: "You can provide result, widget, field"
            }
        }

        const widgetProperty = widget.result.schema.properties[field];

        if(!widgetProperty){
            return {
                valid: false,
                message: "Can't find widget property or current member for this result"
            }
        }

        if(field === "last_member"){
            const lastMemberDimensionId = result.member[result.member.length - 1]?.dimension?.id
            const lastMembers = result.member.filter(member => member.dimension?.id === lastMemberDimensionId)

            if(!widgetProperty?.type.includes('null')){
                const findUnassignedMember = lastMembers.find(member => member.id === MembersIdEnum.unassigned);

                return {
                    valid: !findUnassignedMember,
                    message: findUnassignedMember ? "Pick one options" : ""
                }
            }

            return {
                valid: true,
                message: ""
            }
        }

        const validatorInstance = new Validator();
        const validationResult = validatorInstance.validate(result.measures, widget.result.schema)

        return {
            valid: validationResult.valid,
            error: validationResult,
            message: ValidateResult.getErrorForField(validationResult.errors, field)
        }
    }

    static getErrorForField(errors, field){
        const errorForField = errors.filter(({path}) => path.includes(field));
        return ValidateResult.getErrorMessage(errorForField)
    }

    static getErrorMessage(errors){
        return errors.reduce((message, error) => {
            if(message.length){
                message += "; "
            }

            if(error.schema.exclusiveMinimum){
                return message + t(`ValidationMessage.exclusiveMinimum`, {error})
            }

            if(error.schema.exclusiveMaximum){
                return message + t(`ValidationMessage.exclusiveMaximum`, {error})
            }

            return message + t(`ValidationMessage.${error.name}`, {error})
        }, '')
    }
}

