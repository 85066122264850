import OwnerModel from "../owner.model";
import MemberModel from "../member.model";
import FolderModel from "./folder.model";
import HolderModel from "./holder.model";
import ParseMedicalCardApi from "../../utils/api/ParseMedicalCardApi";

export default class MedicalCardModel {
    constructor(data = {}) {
        this.id = data.id;
        this.owner = data.owner ? new OwnerModel(data.owner) : null; //
        this.created_at = data.created_at;
        this.member = data.member ? data.member.map( member => new MemberModel(member)) : [];
        // this.holder = data.holder ? new HolderModel(data.holder) : null;
        this.holders = data.holders ? data.holders.map(holder => new HolderModel(holder)) : [];
        this.folder = data.folder ? data.folder.map( folder => new FolderModel({
            ...folder,
            medCardMembers: [...this.member, ...this.holders.flatMap(({member}) => member)]
        })) : [];
        this.patient_card = data.patient_card ? new MedicalCardModel(data.patient_card) : null;
        this.facility_card = data.facility_card ? new MedicalCardModel(data.facility_card) : null;
    }

    getResultFromFolderByMemberId(folderId, memberId){
        const desiredFolder = this.folder.find(folder => folder.id === folderId)

        return ParseMedicalCardApi.getResultFromFolderByMemberId(desiredFolder, memberId);
    }

    getResultFromCardByMemberId( resultMember ){
        let desiredResult = null
        const desiredFolder = this.folder.some(folder => {
            const result =  ParseMedicalCardApi.getResultFromFolderByMemberId(folder, resultMember);

            desiredResult = result;

            return !!result
        })

        return desiredResult
    }

    getRecordMemberFromFolderByDimensionId(folderId, recordMemberId, recordDimensionId){
        const desiredRecord = this.getRecordFromFolderByMemberId(folderId, recordMemberId)

        if(!desiredRecord){
            return null
        }

        return desiredRecord.member.find(({dimension}) => dimension.id === recordDimensionId);
    }

    getResultFromFolderAndMemberByMemberId(folderId, recordMemberId, resultMemberId){
        const desiredFolder = this.folder.find(folder => folder.id === folderId)
        const desiredRecord = desiredFolder.record.find(record => record.member.find(({id}) => id === recordMemberId))

        return ParseMedicalCardApi.getResultFromRecordByMemberId(desiredRecord, resultMemberId);
    }

    getResultsFromFolderAndMemberByMemberId(folderId, recordMemberId, resultMemberId){
        const desiredFolder = this.folder.find(folder => folder.id === folderId)
        const desiredRecord = desiredFolder.record.find(record => record.member.find(({id}) => id === recordMemberId))

        return ParseMedicalCardApi.getResultsFromRecordByMemberId(desiredRecord, resultMemberId);
    }

    getRecordFromFolderByMemberId(folderId, recordMemberId){
        const desiredFolder = this.folder.find(folder => folder.id === folderId)

        return desiredFolder.record.find(record => record.member.find(({id}) => id === recordMemberId));
    }

    getAllRecordFromFolderByMemberId(folderId, recordMemberId){
        const desiredFolder = this.folder.find(folder => folder.id === folderId)

        if(!desiredFolder){
            return []
        }

        return desiredFolder.record.filter(record => record.member.find(({id}) => id === recordMemberId));
    }

    getAllRecordFromCardByMemberId(recordMemberId){
        let allRecords = []
        this.folder.forEach(folder => {
            const records = (folder.record || []).filter(record => record.member.find(({id}) => id === recordMemberId))

            allRecords = [...allRecords, ...records]
        })


        return allRecords;
    }

    getMemberByDimensionId(dimensionId){
        return ParseMedicalCardApi.getMemberFromMedicalCardByDimensionId(this, dimensionId)
    }

    findFolder(folderId){
        return this.folder.find(({id}) => id === folderId)
    }

    findFolderByRecordId( recordId ){
        let currentFolder = null;
         this.folder.some((folder) => {
            if(folder.record.length){
                const findRecord = folder.record.find((record) => record.id === recordId)
                if(findRecord){
                    currentFolder = folder
                }
                return false
            }

            return false
        }, null)

        return currentFolder;
    }
}
