/**
 * external libs
 */
import React, {useEffect, useMemo, useState, useContext} from 'react'
/**
 * services
 */
import CatalogService from "../../services/catalog.service";
import LineMember from "./components/LineMember";
import SelectMembersSearchLine from "./components/SearchLine";
import {AlertContext} from "../../pages/context/alert.context";

function formattedName(originalString, strReplace, strWith) {
    if (!strReplace || !strWith) {
        return originalString
    }

    const esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

    const substringIndex = originalString.toLowerCase().indexOf(esc.toLowerCase())

    if (substringIndex === -1) {
        return originalString;
    }

    const firstPartOriginalString = originalString.slice(0, substringIndex)
    const substring = originalString.slice(substringIndex, substringIndex + esc.length)
    const lastPartOriginalString = originalString.slice(substringIndex + esc.length, originalString.length)

    if (lastPartOriginalString.toLowerCase().includes(esc)) {
        return firstPartOriginalString + `<span style="color: black; background: yellow">${substring}</span>` + formattedName(lastPartOriginalString, strReplace, strWith)
    }

    return firstPartOriginalString + `<span style="color: black; background: yellow">${substring}</span>` + lastPartOriginalString
};

const calculateColumnWithIcon = (members) => {
    return members.reduce((columnsWithIcon, membersLine) => {
        const newColumnsWithIcon = columnsWithIcon;
        membersLine.forEach((member) => {
            if (member.icon && !newColumnsWithIcon.includes(member.column)) {
                newColumnsWithIcon.push(member.column)
            }
        })

        return newColumnsWithIcon
    }, [])
}

export default function SelectNewMembers({
                                             initialMember = [],
                                             localMembers = [],
                                             initialOpen = false,
                                             hideUnusedRowsMode = false,
                                             isFullFlow = true,
                                             initialMembersParams = [],
                                             incarnationId,
                                             actionId,
                                             folderId,
                                             getScanResult = () => {
                                             },
                                             getIsFinish = () => {},
                                             catalogId,
                                             cardId,
                                             recordId,
                                             getMembers,
                                             getEmptyFirstRequest = () => {
                                             },
                                         }) {
    const {setAlertError} = useContext(AlertContext)
    const [isReady, setIsReady] = useState(true)
    const [firstViewEmpty, setFirstViewEmpty] = useState(false)
    const [openSelectMember, setOpenSelectMember] = useState(false)
    const [membersData, setMembersData] = useState(null)
    const [includeMember, setIncludeMember] = useState({
        globalInclude: [],
        localInclude: []
    })
    const [originalIncludeMember, setOriginalIncludeMember] = useState({
        globalInclude: [],
        localInclude: []
    })
    const [lastColumn, setLastColumn] = useState(null)
    const [selectedMembers, setSelectedMembers] = useState(initialMember)
    const isRequired = useMemo(() => {
        if (!openSelectMember) {
            return false
        }

        if (!lastColumn || !lastColumn.length) {
            return true
        }


        const currentColumn = lastColumn[lastColumn.length - 1]
        if (!currentColumn?.is_required) {
            return false;
        }

        if (currentColumn?.is_multiple && (currentColumn?.dimension.id === selectedMembers[selectedMembers.length - 1]?.columnOptions?.dimension.id)) {
            return false
        }

        return true
    }, [selectedMembers, lastColumn, openSelectMember])

    const columnsCount = useMemo(() => {
        if (!membersData) {
            return 0
        }

        return membersData.reduce((columnsCount, membersRow) => {
            if (membersRow.length > columnsCount) {
                return membersRow.length
            }

            return columnsCount
        }, 0)
    }, [membersData])

    const getMembersData = async (params = {}) => {
        try {
            setIsReady(false)
            setOpenSelectMember(true)

            const {rows, columns} = await getCatalog(params)

            const newMembersData = deleteDuplicateName(rows);
            setMembersData(newMembersData)

            if (!newMembersData.length) {
                setOpenSelectMember(false)
            }

            return rows;
        } catch (e) {
            setAlertError(e.message)
        } finally {
            setIsReady(true)
        }
    }

    const addMembersData = (newMembers, position, membersIdParams) => {
        if (hideUnusedRowsMode) {
            const newMembersData = deleteDuplicateName(newMembers);
            setMembersData(newMembersData)
            return
        }


        const formattedMembersData = [...membersData]

        let countForDelete = 0;
        formattedMembersData.slice(position).some((row) => {
            const rowsHasMembersParams = !membersIdParams.some((memberId, index) => memberId !== row[index].id)

            if (rowsHasMembersParams) {
                countForDelete += 1;
                return false
            }

            return true
        })

        formattedMembersData.splice(position, countForDelete, ...newMembers)


        const newMembersData = deleteDuplicateName(formattedMembersData);
        setMembersData(newMembersData)
    }

    const removeMembersData = (newMember, position, membersIdParams) => {
        const formattedMembersData = [...membersData]

        let countForDelete = 0;
        formattedMembersData.slice(position).some((row) => {
            const rowsHasMembersParams = !membersIdParams.some((memberId, index) => memberId !== row[index].id)

            if (rowsHasMembersParams) {
                countForDelete += 1;
                return false
            }

            return true
        })

        formattedMembersData.splice(position, countForDelete, newMember)

        const newMembersData = deleteDuplicateName(formattedMembersData);
        setMembersData(newMembersData)
    }

    const deleteDuplicateName = (rows) => {
        return rows.reduce((formattedRows, row, rowIndex) => {
            const prevRows = formattedRows[rowIndex - 1];

            const formattedRow = row.map((column, columnIndex) => {
                //previous column in current row
                const prevColumn = column[columnIndex - 1]

                //column with current index.jsx in previous row
                const prevRowsColumn = prevRows?.[columnIndex]

                //is same columns with current index.jsx in current and prev row
                const isSameColumnNameLikeInPrevRow = prevRowsColumn?.name === column.name;

                //Showing columns if don't have prev column and column with current index.jsx in current row and prev
                // row not the same. OR if have name in previous column and column with current index.jsx in current row and
                // prev row not the same
                const showColumn = !prevColumn && !isSameColumnNameLikeInPrevRow || prevColumn?.name && !isSameColumnNameLikeInPrevRow;

                return {
                    ...column,
                    show: showColumn
                }
            })

            return [
                ...formattedRows,
                formattedRow
            ]
        }, [])
    }

    const getCatalog = async (params = {}) => {
        const currentParams = {
            ...(cardId ? {card_id: cardId} : {}),
            ...(recordId ? {record_id: recordId} : {}),
            ...(catalogId ? {catalog_id: catalogId} : {}),
            ...(folderId ? {folder_id: folderId} : {}),
            ...(actionId ? {action_id: actionId} : {}),
            ...(incarnationId ? {incarnation_id: incarnationId} : {}),
            ...(params.free_text ? {depth: 255} : {depth: 1}),
            ...params
        }

        if (initialMembersParams.length || params.members) {
            currentParams.global_members = [...new Set([
                ...(params.members || []),
                ...(initialMembersParams || []).map(({id}) => id),
            ])].join(',')
        }

        if (localMembers.length) {
            currentParams.local_members = [...new Set([
                ...(localMembers || []).map(({id}) => id),
            ])].join(',')
        }

        const {rows, columns, include} = await CatalogService.content(currentParams)

        const columnsObject = columns.reduce((columnsObject, column) => ({
            ...columnsObject,
            [column.column]: column
        }), {})

        const formattedRows = rows.map((row) => {
            return row.map((column) => ({
                ...column,
                formattedName: formattedName(column.name, params.free_text, "1111"),
                columnOptions: columnsObject[column.column],
            }))
        })

        const getIncludeMembers = (include) => {
            const globalInclude = include?.global_members || []
            const localInclude = include?.local_members || []
            let newSortGlobalArray = []
            let newSortLocalArray = []

            globalInclude.forEach(includeArray => {
                if (!newSortGlobalArray.length) {
                    newSortGlobalArray = includeArray
                    return;
                }

                newSortGlobalArray = newSortGlobalArray.filter((includeInFirstArray) => {
                    return includeArray.some((includeInSecondArray) => includeInFirstArray.id === includeInSecondArray.id)
                });
            })

            localInclude.forEach(includeArray => {
                if (!newSortLocalArray.length) {
                    newSortLocalArray = includeArray
                    return;
                }

                newSortLocalArray = newSortLocalArray.filter((includeInFirstArray) => {
                    return includeArray.some((includeInSecondArray) => includeInFirstArray.id === includeInSecondArray.id)
                });
            })

            return {
                globalInclude: newSortGlobalArray,
                localInclude: newSortLocalArray
            }
        }

        setIncludeMember(getIncludeMembers(include))
        setOriginalIncludeMember(include)
        setLastColumn(columns)

        return {
            rows: formattedRows,
            columns: columns,
        }
    }

    useEffect(() => {
        if (initialOpen) {
            const getMemberDataAsync = async () => {
                const members = await getMembersData()
                if (!members.length) {
                    setFirstViewEmpty(true)
                    getEmptyFirstRequest(true)
                }
            }


            getMemberDataAsync()
        }
    }, [])

    useEffect(() => {
        if (openSelectMember) {
            getMembersData()
        }
    }, [initialMembersParams.length])

    useEffect(() => {
        if (!isRequired) {
            getMembers(selectedMembers, includeMember, originalIncludeMember)
        } else {
            getMembers(
                [],
                {
                    globalInclude: [],
                    localInclude: []
                },
                {
                    globalInclude: [],
                    localInclude: []
                })
        }
    }, [selectedMembers.length, includeMember, isRequired])

    return (
        <div>
            {!firstViewEmpty && (
                <SelectMembersSearchLine getMembersData={getMembersData}
                                         getScanResult={getScanResult}
                                         selectedMembers={selectedMembers}
                                         setSelectedMembers={setSelectedMembers}
                                         openSelectMember={openSelectMember}
                                         isRequired={isRequired}
                                         getIsFinish={getIsFinish}
                                         setOpenSelectMember={setOpenSelectMember}/>
            )}
            {isReady && openSelectMember && membersData && (
                <table>
                    <tbody>
                    {membersData.map((memberData, index) => (
                        <LineMember memberData={memberData}
                                    hideUnusedRowsMode={hideUnusedRowsMode}
                                    key={new Date().getTime() + index}
                                    removeMembersData={removeMembersData}
                                    addMembersData={addMembersData}
                                    columnsCount={columnsCount}
                                    rowPosition={index}
                                    getIsFinish={getIsFinish}
                                    setOpenSelectMember={setOpenSelectMember}
                                    setSelectedMembers={setSelectedMembers}
                                    selectedMembers={selectedMembers}
                                    getCatalog={getCatalog}/>
                    ))}
                    </tbody>
                </table>
            )}
            {!isReady && (
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 50}}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         version="1.0" width="200" height="200"
                         viewBox="0 0 128 128">
                        <path fill="#323238"
                              d="M64.4 16a49 49 0 0 0-50 48 51 51 0 0 0 50 52.2 53 53 0 0 0 54-52c-.7-48-45-55.7-45-55.7s45.3 3.8 49 55.6c.8 32-24.8 59.5-58 60.2-33 .8-61.4-25.7-62-60C1.3 29.8 28.8.6 64.3 0c0 0 8.5 0 8.7 8.4 0 8-8.6 7.6-8.6 7.6z">
                            <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64"
                                              dur="1800ms" repeatCount="indefinite">
                            </animateTransform>
                        </path>
                    </svg>
                </div>
            )}
        </div>
    )
}
