export default class MembersIdEnum {
    static get unassigned() {
        return "6A89BCAA-C74D-4FD6-AA06-D9350AB64913";
    }
    static get contact() {
        return "7A5756D1-7AC7-4B58-8216-263F050A2618";
      }
    static get case() {
        return "ABB7AB2B-70D2-EE11-AAB8-000C29E21A2A";
    }
    static get grossing() {
        return "FF60A00A-1CDA-EE11-AAB8-000C29E21A2A";
    }
    static get other() {
        return "541E29D3-AF5B-EF11-AAE2-000C29E21A2A";
    }
    static get other2() {
        return "F0D96767-185B-EF11-AAE2-000C29E21A2A";
    }
    static get cassette() {
        return "96942BE5-9DD1-EE11-AAB8-000C29E21A2A";
    }
    static get microscopy() {
        return "FE60A00A-1CDA-EE11-AAB8-000C29E21A2A";
    }
    static get slide() {
        return "4E12738A-DC35-EE11-B8F0-6045BD92554D";
    }

    static get diagnosis() {
        return "FE60A00A-1CDA-EE11-AAB8-000C29E21A2A";
    }
    static get specimen() {
        return "1FB315AD-B8D4-EE11-AAB8-000C29E21A2A";
    }
}
