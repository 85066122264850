/**
 * Клас для спрощення роботи з датами
 */
import detectLanguage from "./detectLanguage";

const getDateFormatPattern = (locale) => {
    const getPatternForPart = (part) => {
        switch (part.type) {
            case 'day':
                return 'd'.repeat(part.value.length);
            case 'month':
                return 'm'.repeat(part.value.length);
            case 'year':
                return 'y'.repeat(part.value.length);
            case 'literal':
                return part.value;
            default:
                console.log('Unsupported date part', part);
                return '';
        }
    };

    return new Intl.DateTimeFormat(locale).formatToParts(new Date('2021-01-01'))
        .map(getPatternForPart)
        .join('');
};
const currentDateFormat = getDateFormatPattern(detectLanguage())

export default class DateTime {
    /**
     * Перетворює дату на формат D-m-Y
     */
    static dateToBackendFormat(dateObj) {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();
        const year = dateObj.getFullYear().toString(); // This will get the last two digits of the year
        const hours = dateObj.getHours() <= 9 ? '0' + dateObj.getHours() : dateObj.getHours();
        const minutes = dateObj.getMinutes() <= 9 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();
        const seconds = dateObj.getSeconds() <= 9 ? '0' + dateObj.getSeconds() : dateObj.getSeconds();

        return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`

        // "2023-11-10T12:27:43"
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static toLocaleDate(dateObj, locale = detectLanguage()) {
        return dateObj.toLocaleDateString(locale)
    }

    /**
     * Перетворює дату на формат D-m-Y
     */
    static toLocaleTime(dateObj, locale = detectLanguage()) {
        const [hours, minutes] = dateObj.toLocaleTimeString(locale).split(":")
        return `${hours}:${minutes}`
    }


    /**
     * Перетворює дату на формат D-m-Y
     */
    static dateToDmy(dateObj, split = "-") {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();

        return [date, month, dateObj.getFullYear()].join(split);
    }

    /**
     * Перетворює дату на формат D-m
     */
    static dateToDm(dateObj, split = "-") {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();

        return [date, month].join(split);
    }

    static dateToDmy2(dateObj, split = "-") {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();
        const year = dateObj.getFullYear().toString().substr(2, 2); // This will get the last two digits of the year

        return [date, month, year].join(split);
    }

    /**
     * Перетворює дату на формат Y-m-d
     */
    static dateToYmd(dateObj, split = "-") {
        const month = (dateObj.getMonth() + 1) <= 9 ? '0' + (dateObj.getMonth() + 1) : dateObj.getMonth() + 1;
        const date = dateObj.getDate() <= 9 ? '0' + dateObj.getDate() : dateObj.getDate();

        return [dateObj.getFullYear(), month, date].join(split);
    }

    /**
     * Преобразовывает время в формат H:i:s
     */
    static timeToHis(dateObj) {
        const timeHi = DateTime.timeToHi(dateObj);
        const seconds = dateObj.getSeconds() <= 9 ? '0' + dateObj.getSeconds() : dateObj.getSeconds();

        return [timeHi, seconds].join(':');
    }

    /**
     * Перетворює час на формат H:i:s
     */
    static timeToHi(dateObj) {
        const hours = dateObj.getHours() <= 9 ? '0' + dateObj.getHours() : dateObj.getHours();
        const minutes = dateObj.getMinutes() <= 9 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();

        return [hours, minutes].join(':');
    }

    /**
     * Перетворює дату на формат Y-m-d
     */
    static dateToYmdHis(dateObj) {
        return DateTime.dateToYmd(dateObj) + ' ' + DateTime.timeToHis(dateObj);
    }

    /**
     * Перетворює дату на формат Y-m-d H:i
     */
    static dateToDmyHi(dateObj, separator = "-", separatorBetweenTime = "") {
        return DateTime.dateToDmy(dateObj, separator) + ` ${separatorBetweenTime} ` + DateTime.timeToHi(dateObj);
    }

    static timeToHiAmPm(dateObj) {
        let hours = dateObj.getHours();
        const minutes = dateObj.getMinutes() <= 9 ? '0' + dateObj.getMinutes() : dateObj.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return `${hours}:${minutes} ${ampm}`;
    }

    static addYears(date, years = 1) {
        date.setFullYear(date.getFullYear() + years);

        return date;
    }

    static addMoth(date, month = 1) {
        date.setMonth(date.getMonth() + month);

        return date
    }

    static addWeek(date, weeks = 1) {
        date.setDate(date.getDate() + 7 * weeks);

        return date;
    }
}
