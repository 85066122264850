export default function plTranslate() {
    return {
        "Calendar": "Kalendarz",
        "Notifications": "Powiadomienie",
        "Statistics": "Statystyka",
        "Search": "Szukaj",
        "Create record": "Utwórz rekord",
        "Settings": "Ustawienia",
        "Security": "Bezpieczeństwo",
        "Folders": "Foldery",
        "Catalogs": "Katalogi",
        "Widgets": "Widżety",
        "Language": "Język",
        "Unassigned": "Nieprzypisany",
        "Share": "Współdzielić",
        "Label": "Etykieta",
        "Relation": "Relacja",
        "Validity period": "Termin ważności",
        "Share link": "Udostępnij link",
        "Forever": "Na zawsze",
        "Copied": "Skopiowano",
        "Year": "Rok",
        "Month": "Miesiąc",
        "Yes, I'm sure I want to delete my account and all associated data": "Tak, jestem pewien, że chcę usunąć swoje konto i wszystkie powiązane dane",
        "Delete all data": "Usuń wszystkie dane",
        "Histopathological report": "Raport histopatologiczny",
        "Pathomorphological report": "Raport patomorfologiczny",
        "Date of collection": "Data zbioru",
        "Date of reception": "Data przyjęcia",
        "Answer date": "Data odpowiedzi",
        "Medical card": "Karta medyczna",
        "H/B": "Data urodzenia",
        "Age": "Wiek",
        "Patient": "Pacjent",
        "Sex": "Płeć",
        "Contacts": "Łączność",
        "Medical institution": "Instytucja medyczna",
        "Treating doctor": "Lekarz leczący",
        "Clinical diagnosis": "Diagnoza kliniczna",
        "Clinical data": "Dane kliniczne",
        "Macro": "Makro",
        "Material type": "Typ materiału",
        "Fixation": "Fiksacja",
        "Number of blocks": "Liczba bloków",
        "Decalcification": "Odwapnienie",
        "Macro archive": "Archiwum makr",
        "Dyeing": "Barwiący",
        "Micro": "Mikro",
        "Pathological diagnosis": "Diagnoza patologiczna",
        "Comment": "Komentarz",
        "Pathologist": "Patolog",
        "Week": "Tydzień",
        "Can view": "Może zobaczyć",
        "Can edit": "Może edytować",
        "Can reshare": "Można udostępniać dalej",
        "Scan QR": "Zeskanuj kod QR",
        "Share this medical card": "Udostępnij tę kartę medyczną",
        "Features and tips": "Funkcje i wskazówki",
        "Q&A": "Pytania i odpowiedzi",
        "User agreement": "Zgoda użytkownika",
        "Update the app": "Uaktualnić aplikację",
        "Date": "Data",
        "Value": "Wartość",
        "Time": "Czas",
        "Roles": "Role",
        "Create Permission": "Utwórz pozwolenie",
        "Permissions": "Uprawnienia",
        "All notifications": "Wszystkie powiadomienia",
        "Default": "Domyślny",
        "Diagnosis": "Diagnoza",
        "Status": "Status",
        "Full name": "Pełne imię i nazwisko",
        "Gender and age": "Płeć i wiek",
        "Add account": "Dodaj konto",
        "Log out": "Wyloguj",
        "Action": "Działanie",
        "y.o.": "lat",
        "Create result": "Utwórz wynik",
        "Create New Folder": "Stwórz nowy folder",
        "Upload Catalog": "Prześlij katalog",
        "Create child folder": "Utwórz folder podrzędny",
        "Copy folder id": "Skopiuj identyfikator folderu",
        "Add field": "Dodaj nowy wynik",
        "Insert into record": "Wstaw do rekordu",
        "Insert": "Wstaw",
        "Add": "Dodaj",
        "Go to record": "Przejdź do zapisu",
        "Edit": "Edytować",
        "Edit Catalog": "Edytuj katalog",
        "Icon": "Ikona",
        "Name": "Nazwa",
        "Translation Warnings": "Ostrzeżenia dotyczące tłumaczenia",
        "Copy to clipboard": "Skopiuj do schowka",
        "Ordinal": "Porządkowy",
        "Catalog": "Katalog",
        "Permit": "Zezwolenie",
        "Included": "Dołączony",
        "Enabled": "Włączone",
        "Enable": "Włączyć",
        "Excluded": "Wyłączony",
        "Permitted": "Dozwolony",
        "Dimensions": "Wymiary",
        "Included members": "Uwzględnieni członkowie",
        "Catalog Name": "Nazwa katalogu",
        "Dimensions for": "Wymiary dla",
        "Explore": "Badać",
        "Is required": "Jest wymagane",
        "Is restricted": "Jest ograniczony",
        "Edit record": "Edytuj rekord",
        "Go to widget": "Przejdź do widżetu",
        "Is multiple": "Jest wielokrotny",
        "Dimension": "Wymiar",
        "Count": "Liczenie",
        "Save": "Ratować",
        "Cancel": "Anulować",
        "Excluded members": "Wykluczeni członkowie",
        "Row count": "Liczba wierszy",
        "Explore JSON": "Przeglądaj JSON",
        "Explore CSV": "Przeglądaj CSV",
        "Download catalog": "Pobierz katalog",
        "Member": "Członek",
        "Result": "Wynik",
        "Actions": "Działania",
        "Duplicate": "Duplikować",
        "Copy Id": "Skopiuj identyfikator",
        "Delete": "Usuwać",
        "Create New Widget": "Utwórz nowy widget",
        "General": "Ogólny",
        "Select data": "Wybierz dane",
        "Select date": "Wybierz datę",
        "Page not found": "Strona nie znaleziona",
        "Include": "Włączać",
        "Incarnation": "Wcielenie",
        "Reset Password": "Zresetuj hasło",
        "Parent Folder": "Folder nadrzędny",
        "Edit Folder": "Edytuj folder",
        "Edit Widget": "Edytuj widżet",
        "Exclude": "Wykluczać",
        "Members": "Członkowie",
        "Translation": "Tłumaczenie",
        "Will come soon": "Przyjdzie wkrótce",
        "Are you sure you want to delete this catalog?": "Czy na pewno chcesz usunąć ten katalog?",
        "Add glass QR code": "Dodaj kod QR szkła",
        "Create glass QR code": "Utwórz kod QR szkła",
        "Yes": "Tak",
        "No": "NIE",
        "Data saved successfully!": "Dane zostały zapisane pomyślnie!",
        "Permitted members": "Dozwoleni członkowie",
        "Failed to save data.": "Nie udało się zapisać danych.",
        "Translations": "Tłumaczenia",
        "Are you sure you want to delete this result?": "Czy na pewno chcesz usunąć ten wynik?",
        "Are you sure you want to delete this record?": "Czy na pewno chcesz usunąć ten rekord?",
        "Are you sure you want to delete this medical card?": "Czy na pewno chcesz usunąć tę kartę medyczną?",
        "You successfully removed medical card": "Pomyślnie usunąłeś kartę medyczną",
        "The medical record you are trying to delete does not exist!": "Dokumentacja medyczna, którą próbujesz usunąć, nie istnieje!",
        "There are no records in this folder yet.": "W tym folderze nie ma jeszcze żadnych rekordów.",
        "Fill in required fields!": "Wypełnij wymagane pola!",
        "Policy Pages": "Strony z zasadami",
        "User Agreement": "Zgoda użytkownika",
        "Personal info": "Dane osobiste",
        "You must fix the validation error": "Musisz naprawić błąd sprawdzania poprawności",
        "ValidationMessage.minimum": "Wiadomość o walidacji.minimum",
        "ValidationMessage.maximum": "Komunikat o walidacji.maksimum",
        "ValidationMessage.exclusiveMinimum": "Wiadomość walidacyjna.exclusiveMinimalna",
        "ValidationMessage.exclusiveMaximum": "Wiadomość walidacyjna.exclusiveMaksymalna",
        "ValidationMessage.multipleOf": "Wiadomość walidacyjna.multipleOf",
        "ValidationMessage.minLength": "Wiadomość o walidacji.minDługość",
        "ValidationMessage.maxLength": "Wiadomość o walidacji.maxDługość",
        "ValidationMessage.pattern": "Wzorzec wiadomości o walidacji",
        "ValidationMessage.type": "Typ wiadomości walidacyjnej",
        "ValidationMessage.format": "Wiadomość walidacyjna.format",
        "The result must have at least one member": "Wynik musi mieć co najmniej jednego członka"
    }
}

