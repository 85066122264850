/**
 * external libs
 */
import React from 'react'
import {Link} from "react-router-dom";
/**
 * styles
 */
import styles from '../../index.module.scss'
/**
 * const
 */
import {HOME_PAGE_URI} from "../../../../constants/uri.constant";
import Logo from '../../../../assets/logo.svg'

export default function SideBarTop({ setOpenSideBar, openSideBar }){
    return (
        <div className={styles.topSideBar}>
            <Link to={HOME_PAGE_URI}>
                <img src={process.env.REACT_APP_LOGO_LINK || Logo} alt="logo" className={styles.topSideBar__logo}/>
            </Link>
            <div className={styles.topSideBar__btn} onClick={() => setOpenSideBar(!openSideBar)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 6L9 12L15 18" stroke="#323238" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    )
}
