import WidgetNameEnum from "../../enums/api/WidgetName";
import WidgetDateTime from "./components/WidgetDateTime";
import WidgetDate from "./components/WidgetDate";
import WidgetTime from "./components/WidgetTime";
import WidgetLastMember from "./components/WidgetLastMember";
import WidgetURL from "./components/WidgetURL";
import WidgetPhone from "./components/WidgetPhone";
import WidgetLink from "./components/WidgetLink";
import WidgetString from "./components/WidgetString";
import WidgetNumber from "./components/WidgetNumber";
import WidgetIntNumber from "./components/WidgetIntNumber";
import React from "react";
import WidgetMarkdown from "./components/WidgetMarkdown";
import getWidget from "../../utils/getWidget";

export default function LocalWidgetWrapper({
                                               widget,
                                               initialFocused,
                                               excludeWidget,
                                               newResult,
                                               isEditableMode,
                                               readOnly,
                                               field,
                                               editLocalWidget,
                                               changeMembersHandler,
                                               index = 0,
                                               ...props
                                           }) {
    const isFocused = initialFocused && (index === 0);
    const widgetProps = field ? widget.result.schema.properties[field] : widget.result.schema




    if (widgetProps.format === "datetime" && !excludeWidget.includes(WidgetNameEnum.datetime)) {
        return <WidgetDateTime result={newResult}
                               readOnly={readOnly}
                               field={field}
                               isFocused={isFocused}
                               editable={isEditableMode}
                               onEdit={editLocalWidget}
                               widget={widget}
                               {...props}/>
    }

    if (widgetProps.format === "date" && !excludeWidget.includes(WidgetNameEnum.date)) {
        return <WidgetDate result={newResult}
                           readOnly={readOnly}
                           field={field}
                           isFocused={isFocused}
                           editable={isEditableMode}
                           onEdit={editLocalWidget}
                           widget={widget}
                           {...props}/>
    }

    if (widgetProps.format === "time" && !excludeWidget.includes(WidgetNameEnum.time)) {
        return <WidgetTime result={newResult}
                           readOnly={readOnly}
                           field={field}

                           isFocused={isFocused}
                           editable={isEditableMode}
                           onEdit={editLocalWidget}
                           widget={widget}
                           {...props}/>
    }

    if (field === "last_member") {
        if (excludeWidget.includes(WidgetNameEnum.lastMember)) {
            return null;
        }

        return <WidgetLastMember result={newResult}
                                 readOnly={readOnly}
                                 field={field}
                                 isFocused={isFocused}
                                 editable={isEditableMode}
                                 onEdit={editLocalWidget}
                                 widget={widget}
                                 changeMembersHandler={changeMembersHandler}
                                 {...props}/>
    }

    if (widgetProps['x-path']) {
        return <WidgetURL result={newResult}
                          readOnly={readOnly}
                          field={field}
                          isFocused={isFocused}
                          editable={isEditableMode}
                          onEdit={editLocalWidget}
                          widget={widget}
                          {...props}/>
    }

    if (widgetProps.format === "phone" && !excludeWidget.includes(WidgetNameEnum.phone)) {
        return <WidgetPhone result={newResult}
                            readOnly={readOnly}
                            field={field}
                            isFocused={isFocused}
                            editable={isEditableMode}
                            onEdit={editLocalWidget}
                            widget={widget}
                            {...props}/>
    }

    if (widgetProps.format === "markdown" && !excludeWidget.includes(WidgetNameEnum.markdown)) {
        return <WidgetMarkdown result={newResult}
                               readOnly={readOnly}
                               field={field}
                               isFocused={isFocused}
                               editable={isEditableMode}
                               onEdit={editLocalWidget}
                               widget={widget}
                               {...props}/>
    }

    if (widgetProps.format === "uri" && !excludeWidget.includes(WidgetNameEnum.link)) {
        return <WidgetLink result={newResult}
                           readOnly={readOnly}
                           field={field}
                           isFocused={isFocused}
                           editable={isEditableMode}
                           onEdit={editLocalWidget}
                           widget={widget}
                           {...props}/>
    }

    if (widgetProps.type.includes("string") && !excludeWidget.includes(WidgetNameEnum.string)) {
        return <WidgetString result={newResult}
                             readOnly={readOnly}
                             field={field}
                             widget={widget}
                             isFocused={isFocused}
                             editable={isEditableMode}
                             onEdit={editLocalWidget}
                             {...props}/>
    }


    if (widgetProps.type.includes("number") && !excludeWidget.includes(WidgetNameEnum.number)) {
        return <WidgetNumber result={newResult}
                             readOnly={readOnly}
                             field={field}
                             isFocused={isFocused}
                             editable={isEditableMode}
                             onEdit={editLocalWidget}
                             widget={widget}
                             {...props}/>
    }

    if (widgetProps.type.includes("integer") && !excludeWidget.includes(WidgetNameEnum.integer)) {
        return <WidgetIntNumber result={newResult}
                                readOnly={readOnly}
                                field={field}
                                isFocused={isFocused}
                                editable={isEditableMode}
                                onEdit={editLocalWidget}
                                widget={widget}
                                {...props}/>
    }

    return null
}
